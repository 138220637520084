<template>
  <v-container>
    <v-row>
      <v-col class="text-h5">Ik wil graag afrekenen door middel van</v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" lg="6">
        <v-form ref="form" v-model="valid">
          <v-radio-group v-model="paymentMethod" :rules="[v => !!v || 'Betaalmethode is verplicht']">
            <VRadio :label="methodsName[methodsValues.IDEAL]" :value="methodsValues.IDEAL" color="primary"/>

            <v-row no-gutters>
              <VRadio class="pr-3" label="Factuur" value="2" color="primary" :disabled="!canOrderOnInvoice"/>
              <v-tooltip right v-if="!canOrderOnInvoice">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">far fa-info-circle</v-icon>
                </template>
                <span>Deze optie is alleen mogelijk wanneer u dit telefonisch of per e-mail aanvraagt bij FIS</span>
              </v-tooltip>
            </v-row>
          </v-radio-group>
        </v-form>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-btn color="primary" @click="handlePaymentForm">Naar stap 4</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import BaseForm from '@/components/BaseForm.vue';
import { paymentMethodNames, paymentMethods } from '@/application/enums/paymentMethods.js';

export default {
  name: 'TabPayment',
  extends: BaseForm,
  computed: {
    ...mapGetters('order', ['paymentMethodId']),
    ...mapGetters('profile', ['profile']),
    methodsValues: () => paymentMethods,
    methodsName: () => paymentMethodNames,
    paymentMethod: {
      get() {
        return this.paymentMethodId;
      },
      set(value) {
        this.setPaymentMethod(value);
        this.setHasFinishedPaymentTab(!!value);
      },
    },
    canOrderOnInvoice() {
      return this.profile.canOrderOnInvoice;
    },
  },
  methods: {
    ...mapMutations('order', ['setPaymentMethod', 'setHasFinishedPaymentTab']),
    handlePaymentForm() {
      this.validate();
      this.setHasFinishedPaymentTab(this.valid);

      if (this.valid) {
        this.$emit('nextStep');
      }
    },
  },
};
</script>
