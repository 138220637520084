<template>
  <VSelect v-model="selectedType"
           label="Levertijd"
           :items="deliveryTypes"/>
</template>

<script>
import { deliveryTypeNames, deliveryTypes } from '@/application/enums/deliveryType.js';

export default {
  name: 'DeliveryTypeSelect',
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      selectedType: deliveryTypes.DEFAULT,
    };
  },
  created() {
    this.selectedType = this.value;
  },
  watch: {
    selectedType() {
      this.$emit('input', this.selectedType);
    },
  },
  computed: {
    deliveryTypes() {
      return Object.keys(deliveryTypes).map(key => {
        return {
          text: deliveryTypeNames[deliveryTypes[key]],
          value: deliveryTypes[key],
        };
      });
    },
  },
};
</script>
