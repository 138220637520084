<template>
  <v-row justify-md="center" class="ma-0">
    <v-col md="6" justify-md="center" v-if="hasOrderLines">
      <v-alert type="error" v-if="hasValidationError">
        <p v-if="errorMessage">{{ errorMessage }}</p>
        <ul v-else>
          <li v-for="(messages, name) in errors" :key="name">
            {{ messages[0] }}
          </li>
        </ul>
      </v-alert>
      <v-tabs
          right
          v-model="step"
          background-color="transparent"
      >
        <v-tab>Uw gegevens</v-tab>
        <v-tab :disabled="!hasFinishedPersonalTab">Verzending</v-tab>
        <v-tab :disabled="!hasFinishedShipmentTab">Betaling</v-tab>
        <v-tab :disabled="!hasFinishedPaymentTab">Overzicht</v-tab>

        <v-tab-item>
          <TabPersonal @nextStep="step = 1"/>
        </v-tab-item>
        <v-tab-item>
          <TabShipment @nextStep="step = 2"/>
        </v-tab-item>
        <v-tab-item>
          <TabPayment @nextStep="step = 3"/>
        </v-tab-item>
        <v-tab-item>
          <TabOverview @nextStep="handlePayment" :loading="loading"/>
        </v-tab-item>
      </v-tabs>
    </v-col>
    <v-col md="6" justify-md="center" v-else>
      <p>Om af te kunnen rekenen dient u eerst producten in uw
        <span class="primary--text cursor-pointer" @click="$router.push({name:'shopping-cart'})">winkelwagen</span>
        te hebben
      </p>
    </v-col>
  </v-row>
</template>

<script>
import TabPersonal from '@/components/Order/TabPersonal.vue';
import TabShipment from '@/components/Order/TabShipment.vue';
import TabPayment from '@/components/Order/TabPayment.vue';
import TabOverview from '@/components/Order/TabOverview.vue';
import { mapGetters } from 'vuex';
import { makeOrder, makeGuestOrder } from '@/api/order';
import { Order } from '@/application/models/Order.js';

export default {
  name: 'MakeOrder',
  components: {
    TabOverview,
    TabPayment,
    TabShipment,
    TabPersonal,
  },
  data() {
    return {
      step: 0,
      hasValidationError: false,
      errors: [],
      errorMessage: '',
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'authorisation/isLoggedIn',
      isReadyToOrder: 'order/isReadyToOrder',
      hasFinishedPersonalTab: 'order/hasFinishedPersonalTab',
      hasFinishedShipmentTab: 'order/hasFinishedShipmentTab',
      hasFinishedPaymentTab: 'order/hasFinishedPaymentTab',
      order: 'order/order',
    }),
    ...mapGetters('order', ['orderLines', 'hasOrderLines']),
  },
  created() {
    if (!this.isReadyToOrder && !this.isLoggedIn) {
      this.$router.push({ name: 'order-account' });
    } else if (this.$route.params?.step) {
      this.step = this.$route.params.step;
    }
    this.$hero.$emit('setTitle', 'Winkelwagen');
    this.$hero.$emit('setCanSearch', false);
    this.$hero.$emit('setBreadcrumbs', [
      {
        text: 'home',
        exact: true,
        to: { name: 'home' },
      },
      {
        text: 'winkelwagen',
        to: { name: 'shopping-cart' },
      },
      {
        text: 'bestellen',
        to: { name: 'make-order' },
      },
    ]);
  },
  methods: {
    handlePayment: async function () {
      this.loading = true;
      this.hasValidationError = false;
      const order = new Order().mapResponse(this.order);

      if (order.deliverAt === null) {
        order.deliverAt = undefined;
      }

      try {
        const { data: { data } } = (this.isLoggedIn) ? await makeOrder(order.mapForRequest()) : await makeGuestOrder(order.mapForRequest());

        if (data && data.hasAction) {
          this.redirectToPage(data.action?.data?.redirectUrl);
        }
      } catch (err) {
        if (err.response && err.response.status === 422) {
          this.errorMessage = '';
          this.hasValidationError = true;
          this.errors = err.response.data.errors;
        } else {
          this.errorMessage = 'Er is iets misgegaan';
        }

        this.$vuetify.goTo(0);
      }
      this.loading = false;
    },
    redirectToPage(url) {
      if (url) {
        location.href = url;
      }
    },
  },
};
</script>

<style>
.theme--light.v-tabs-items {
  background: transparent;
}
</style>
