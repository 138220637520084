<template>
  <v-form ref="form" v-model="valid">
    <v-row class="pa-0">
      <VTextField
          label="Naam"
          class="col col-12 pb-0 required"
          :rules="[v => !!v || 'Uw naam is verplicht', () => serverError('name')]"
          v-model="address.attention"
      />
      <VTextField
          label="Bedrijfsnaam"
          class="col col-12"
          :rules="[() => serverError('company')]"
          v-model="address.company"
      />
      <VTextField
          label="Straat"
          class="col col-8 py-0 required"
          :rules="[v => !!v || 'Uw straat is verplicht', () => serverError('street')]"
          v-model="address.street"
      />
      <VTextField
          label="Huisnummer"
          class="col col-4 py-0 required"
          :rules="[v => !!v || 'Uw huisnummer is verplicht', () => serverError('houseNumber')]"
          v-model="address.houseNumber"
      />
      <VTextField
          label="Postcode"
          class="col col-4 pb-0 required"
          :rules="[v => !!v || 'Uw postcode is verplicht', () => serverError('postalCode')]"
          v-model="address.postalCode"
      />
      <VTextField
          label="Woonplaats"
          class="col col-8 pb-0 required"
          :rules="[v => !!v || 'Uw woonplaats is verplicht', () => serverError('city')]"
          v-model="address.city"
      />
      <VTextField class="col col-12 pt-2"
                  label="Telefoonnummer"
                  v-model="address.phone"/>
      <slot/>
      <p class="black--text col pt-0">* = velden zijn verplicht</p>
    </v-row>
  </v-form>
</template>

<script>
import BaseForm from '@/components/BaseForm.vue';

export default {
  name: 'AddressForm',
  extends: BaseForm,
  props: {
    value: {
      required: true,
      type: Object,
    },
  },
  computed: {
    address: {
      get() {
        return this.value;
      },
      set(address) {
        this.$emit('input', address);
      }
    }
  }
};
</script>
