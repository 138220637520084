<template>
  <v-container>
    <v-row>
      <v-col class="text-h5">Overzicht</v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row v-for="(product, index) in order.orderLines" :key="index" class="my-3" no-gutters>
          <CartCard :product="product" />
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-row class="text-h6 mt-3 mb-2" no-gutters>
          Verzending
        </v-row>
        <Address :address="order.shippingAddress"/>
        <v-row class="text-h6 mt-3 mb-2" no-gutters>
          Facturering
        </v-row>
        <Address :address="order.invoiceAddress"/>
      </v-col>
      <v-col cols="12" md="6" class="mt-3">
        <CartCosts :order-lines="order.orderLines"/>
        <v-row no-gutters>
          <span>Betalen met:</span>
          <VSpacer/>
          <span>{{ methodsName[order.paymentMethodId] }}</span>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card class="pa-12">
          <v-row>
            <v-col cols="12" sm="6" md="6" class="mt-3">
              <v-row class="text-h6 mb-2" no-gutters>
                Afbeeldingen
              </v-row>
              <p> Indien gewenst kunt u hier één of meerdere foto's van uw auto uploaden, zodat wij kunnen controleren of er besteld wordt voor de juiste auto. </p> 
            </v-col>
            <v-col cols="12" sm="6" md="6" class="mt-3">
              <KFileField
                field="images"
                label="Bestanden"
                :multiple="true"
                v-model="orderImages"
                only-images
                :aspect-ratio="2"
                button-text="Upload afbeelding(en)"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols-lg="4" offset-lg="8" class="py-8">
          <VTextField class="mb-2" label="Referentie (optioneel)" v-model="order.orderReference" counter  maxlength="30"/>
          <v-btn width="100%" color="primary" @click="$emit('nextStep')" :loading="loading">Bestelling plaatsen</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Address from '@/components/Address.vue';
import CartCard from '@/components/CartCard.vue';
import CartCosts from '@/components/CartCosts.vue';
import { paymentMethodNames } from '@/application/enums/paymentMethods.js';
import KFileField from '@/components/crud/fields/KFileField.vue';

export default {
  name: 'TabOverview',
  components: {
    KFileField,
    CartCosts,
    CartCard,
    Address,
  },
  props: {
    loading: {
      type: Boolean,
    },
  },
  computed: {
    methodsName: () => paymentMethodNames,
    ...mapGetters('order', ['order']),
    ...mapGetters('order', ['images', 'orderReference']),
    orderImages: {
      get() {
        return this.images;
      },
      set(value) {
        this.setImages(value);
      },
    },
    orderOrderReference: {
      get: () => this.orderReference,
      set: (value) => this.setOrderReference(value),
    },
  },
  methods: {
    ...mapMutations('order', ['setImages', 'setOrderReference']),
  },
};
</script>

<style scoped>

</style>
