<template>
  <v-form ref="form" v-model="valid">
    <slot/>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        min-width="auto"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
            v-model="date"
            label="Kies een afleverdatum"
            prepend-icon="fa-calendar"
            class="required"
            :rules="[v => !!v || 'Afleverdatum is verplicht', v => !!v && hasCorrectDate() || 'Afleverdatum is niet geldig']"
            readonly
            v-bind="attrs"
            v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
          v-model="date"
          no-title
          :readonly="isExpress"
          scrollable
          locale="nl-NL"
          first-day-of-week="1"
          :min="minDeliveryDateAt"
          :max="maxDeliveryDateAt"
          :allowed-dates="v => allowedDates.indexOf(v) !== -1"
          @input="handleDatePicked"
      >
      </v-date-picker>
    </v-menu>
  </v-form>
</template>

<script>
import BaseForm from '@/components/BaseForm.vue';
import dayjs from '@/plugins/dayJs.js';
import { deliveryTypes } from '@/application/enums/deliveryType.js';

export default {
  name: 'DeliveryAtForm',
  extends: BaseForm,
  data() {
    return {
      menu: false,
      date: null,
      // TODO: Not able for selecting dates in next month
      allowedDates: [],
    };
  },
  props: {
    value: {
      type: String,
    },
    deliveryType: {
      default: deliveryTypes.DEFAULT,
    },
  },
  computed: {
    minDeliveryDate() {
      const possibleDate = dayjs()
          .add(this.possibleDeliveryInDays, 'day');

      return this.getDateOutsideWeekend(possibleDate);
    },
    minDeliveryDateAt() {
      return this.minDeliveryDate
          .format('YYYY-MM-DD');
    },
    maxDeliveryDateAt() {
      if (this.isExpress) {
        return this.getDateOutsideWeekend(dayjs()
            .add(this.possibleDeliveryInDays, 'day'),
        ).format('YYYY-MM-DD');
      }

      return null;
    },
    isExpress() {
      return this.deliveryType === deliveryTypes.EXPRESS;
    },
    possibleDeliveryInDays() {
      return this.isExpress ? 1 : 3;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.date = this.value;
      },
    },
    deliveryType: {
      handler() {
        this.setDefaultDate();
        this.setAllowedDates();
      },
    },
  },
  mounted() {
    if (!this.hasCorrectDate()) {
      this.setDefaultDate();
    }
    this.setAllowedDates();
  },
  methods: {
    handleDatePicked() {
      if (this.$refs.menu) {
        this.$refs.menu.save(this.date);
      }
      this.$emit('input', this.date);
    },
    setDefaultDate() {
      this.date = this.minDeliveryDateAt;
      this.handleDatePicked();
    },
    hasCorrectDate() {
      return this.date === null || dayjs(this.date).diff(this.minDeliveryDateAt) >= 0;
    },
    getDateOutsideWeekend(date) {
      const dayOfWeek = date.day();
      return this.IsWeekend(date) ? date.add(dayOfWeek === 6 ? 2 : 1, 'day') : date;
    },
    IsWeekend(date) {
      const dayOfWeek = date.day();
      return dayOfWeek === 6 || dayOfWeek === 0;
    },
    setAllowedDates() {
      this.allowedDates = [];
      let date = this.minDeliveryDate;

      if (this.isExpress) {
        this.allowedDates.push(date.format('YYYY-MM-DD'));
        return;
      }

      for (let x = this.minDeliveryDate.date(); x < 150; x++) {
        if (!this.IsWeekend(date)) {
          this.allowedDates.push(date.format('YYYY-MM-DD'));
        }
        date = date.add(1, 'day');
      }
    },
  },
};
</script>

<style scoped>

</style>
