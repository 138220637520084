<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12">
        <h5 class="text-h5">Verzending</h5>

        <VCheckbox color="primary"
                   v-model="shipmentIsPickUp"
                   label="Ik wil mijn bestelling ophalen"/>
        <VSheet height="3vh" width="100%"/>
      </v-col>
      <v-col cols="12" md="6" class="pr-3" v-if="!shipmentIsPickUp">
        <h5 class="text-h5">Afleveradres</h5>

        <Address :address="shippingAddress" class="mb-4"/>
        <delivery-at-form v-model="shipmentDeliverAt"
                          v-if="!shipmentIsPickUp"
                          ref="deliveryAtForm"
                          :delivery-type="expressDeliveryType">
          <DeliveryTypeSelect v-model="expressDeliveryType"/>
        </delivery-at-form>
        <small>Verzendkosten zijn standaard 45,- van een bestelling onder 300,-. Vanwege de garantie van een
          spoedlevering worden de verzendkosten altijd 100,- ongeacht het bestelbedrag.
        </small>
      </v-col>
      <v-col cols="12" md="6" class="pr-3" v-else>
        <h5 class="text-h5">Adresgegevens</h5>

        <FisAddressInformation/>
      </v-col>
      <v-col cols="12" md="6" class="pl-3">
        <h5 class="text-h5">Factuuradres</h5>
        <VCheckbox color="primary"
                   v-model="areTheSame"
                   label="Het factuuradres is hetzelfde als het afleveradres"/>

        <AddressForm v-if="!areTheSame" v-model="shipmentInvoiceAddress" ref="invoiceForm"/>
      </v-col>
    </v-row>
    <v-row no-gutters justify="end">
      <v-btn color="primary" @click="handleNextStep">Naar stap 3</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Address from '@/components/Address.vue';
import AddressForm from '@/components/Order/forms/AddressForm.vue';
import DeliveryAtForm from '@/components/Order/forms/DeliveryAtForm.vue';
import FisAddressInformation from '@/components/FisAddressInformation.vue';
import DeliveryTypeSelect from '@/components/Order/forms/DeliveryTypeSelect.vue';

export default {
  name: 'TabShipment',
  components: {
    DeliveryTypeSelect,
    FisAddressInformation,
    DeliveryAtForm,
    AddressForm,
    Address,
  },
  computed: {
    ...mapGetters('order', [
      'shippingAddress',
      'invoiceAddress',
      'addressesAreTheSame',
      'isPickUp',
      'deliverAt',
      'expressDelivery',
    ]),
    shipmentInvoiceAddress: {
      get() {
        return this.invoiceAddress;
      },
      set(value) {
        this.setInvoiceAddress(value);
      },
    },
    areTheSame: {
      get() {
        if (this.addressesAreTheSame) {
          this.copyShippingAddressToInvoiceAddress();
        }
        return this.addressesAreTheSame;
      },
      set(value) {
        this.setAddressesAreTheSame(value);
      },
    },
    shipmentIsPickUp: {
      get() {
        return this.isPickUp;
      },
      set(value) {
        this.setIsPickUp(value);
      },
    },
    shipmentDeliverAt: {
      get() {
        return this.deliverAt;
      },
      set(value) {
        this.setDeliverAt(value);
      },
    },
    expressDeliveryType: {
      get() {
        return this.expressDelivery;
      },
      set(value) {
        this.setExpressDelivery(value);
      },
    },
  },
  created() {
    this.copyShippingAddressToInvoiceAddress();
  },
  watch: {
    invoiceAddress: {
      deep: true,
      handler() {
        this.setHasFinishedShipmentTab(!this.hasServerErrors());
      },
    },
  },
  methods: {
    ...mapMutations('order', [
      'setInvoiceAddress',
      'setAddressesAreTheSame',
      'setIsPickUp',
      'setDeliverAt',
      'setHasFinishedShipmentTab',
      'setExpressDelivery',
    ]),
    handleNextStep() {
      this.$refs.invoiceForm?.validate();
      this.$refs.deliveryAtForm?.validate();

      this.setHasFinishedShipmentTab(!this.hasServerErrors());

      if (this.hasServerErrors()) {
        return;
      } else if (this.addressesAreTheSame) {
        this.copyShippingAddressToInvoiceAddress();
      }

      this.$emit('nextStep');
    },
    copyShippingAddressToInvoiceAddress() {
      this.shipmentInvoiceAddress = JSON.parse(JSON.stringify(this.shippingAddress));
    },
    hasServerErrors() {
      return !!this.$refs.invoiceForm && !this.$refs.invoiceForm.valid || !!this.$refs.deliveryAtForm && !this.$refs.deliveryAtForm.valid;
    },
  },
};
</script>
